import React from 'react';
import Layout from '../../components/Layout';
import Section from '../../components/Section';
import { Container, Row, Column } from '../../components/Grid';
import { Link } from 'gatsby';
import Image from '../../components/Image';
import Icon from '../../components/Icon';

const VirtualFieldTrips = () => (
  <Layout title="Virtual Field Trips" className="science-starters">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-purple"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb">
            <Link to="/science-starters/getting-started">Science Starters</Link>
          </li>
          <li className="breadcrumb active">Virtual Field Trips</li>
        </ul>
        <h1 className="page-title">Virtual Field Trips</h1>
        <Image filename="hero-VFT-landing.png" className="hero-image" />
      </div>
    </div>
    {/* Getting Started */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro">
            <h4>
              Transport students to unique science hubs and other amazing
              environments with these immersive virtual experiences, featuring
              diverse subject matter experts and 3M Scientists.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* How Nature Inspires 3M Science */}
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={6}>
            <Image filename="image-how-nature-inspires-science-promo-1.jpg" />
          </Column>
          <Column size={5} className="pb-4">
            <h3 className="text-34 mt-2">How Nature Inspires 3M Science</h3>
            <p className="text-24">
              Take your students on a Virtual Field Trip to 3M World
              Headquarters to explore how 3M scientists use biomimicry to
              improve and invent new products for everything from healthcare to
              food science to adhesives.
            </p>
            <Link
              to="how-nature-inspires-3M-science"
              className="cta__link no-arrow bg-gradient-purple"
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Inside the World of Science */}
    <Section className="mb-2">
      <Container>
        <Row>
          <Column size={5} offset={1} className="pr-1 pb-2">
            <h3 className="text-34 mt-2">Inside the World of Science</h3>
            <p className="text-24">
              Give students a behind-the-scenes look at 3M Scientists doing
              experiments you can only see at 3M World Headquarters. Find out
              how 3M products help improve our everyday lives and meet the pros
              that make this innovation possible.
            </p>
            <Link
              to="inside-the-world-of-science"
              className="cta__link no-arrow bg-gradient-purple"
              style={{ width: '95%' }}
            >
              Learn More <Icon name="arrowright" />
            </Link>
          </Column>
          <Column size={6}>
            <Image filename="image-inside-the-world-of-science-promo-2.jpg" />
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default VirtualFieldTrips;
